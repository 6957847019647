import { useMemo } from "react"

import { useAppSelector } from "../../../../../app/hooks"
import MetricBadge from "../../../../../app/shared/MetricBadge"
import { selectMonth } from "../../../HistoricalChartsScreen/historicalSlice"
import { selectMonthlyKPIs } from "../../overviewSlice"
import { GoalScoreType } from "../../overviewTypes"
import styles from "./index.module.scss"

interface RowType {
  isPercentage: boolean
  KPI: string
  currentValue: string
  MoMChange: number
  currentGoal?: string
  goalDelta?: number
  goalScore?: GoalScoreType
}

const columnHeaders = [
  "KPI",
  "currentMonth",
  "MoM% Change*",
  "currentMonth Goal",
  "% Goal Delta",
  "Goal Score",
]

export const MonthlyKPITable = () => {
  const selectedMonth = useAppSelector(selectMonth)
  const monthlyKPI = useAppSelector(selectMonthlyKPIs)

  const rowValues: RowType[] = useMemo(
    () =>
      (monthlyKPI || []).map((monthKPI) => ({
        isPercentage: monthKPI.isPercentage,
        KPI: monthKPI.kpi,
        currentValue: monthKPI.month,
        MoMChange: monthKPI.monthChange,
        currentGoal: monthKPI.monthGoal,
        goalDelta: monthKPI.goalDelta,
        goalScore: monthKPI.goalScore,
      })),
    [monthlyKPI],
  )

  return (
    <div className={styles.monthlyKPITable}>
      <TableHeader currentMonth={selectedMonth.label} />
      {rowValues.map((rowValue, i) => (
        <Row rowValue={rowValue} key={i} />
      ))}
    </div>
  )
}

const TableHeader = ({ currentMonth }: { currentMonth: string }) => (
  <div className={styles.header}>
    {columnHeaders.map((title) => (
      <div key={title}>{title.replace("currentMonth", currentMonth)}</div>
    ))}
  </div>
)

const Row = ({ rowValue }: { rowValue: RowType }) => (
  <div className={styles.row}>
    <div className={styles.kpi}>{rowValue.KPI}</div>
    <div className={styles.currentMonth}>
      {rowValue.currentValue}
      {rowValue.isPercentage ? "%" : ""}
    </div>
    <div className={styles.cell}>
      <MetricBadge numericValue={rowValue.MoMChange} isPercentage />
    </div>
    <div className={styles.cell}>
      {rowValue.currentGoal
        ? `${rowValue.currentGoal}
      ${rowValue.isPercentage ? "%" : ""}`
        : "n/a"}
    </div>
    <div className={styles.cell}>
      {rowValue.goalDelta ? (
        <MetricBadge numericValue={rowValue.goalDelta} isPercentage />
      ) : (
        "n/a"
      )}
    </div>
    <div className={styles.cell}>
      {rowValue.goalScore !== GoalScoreType.NA ? (
        <MetricBadge
          stringValue={rowValue.goalScore}
          isPercentage={false}
          numericValue={
            rowValue.goalScore === GoalScoreType.AT_RISK
              ? -1
              : rowValue.goalScore === GoalScoreType.ON_TRACK
              ? 1
              : 0
          }
        />
      ) : (
        "n/a"
      )}
    </div>
  </div>
)
